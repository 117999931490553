export class TestLocal {
    id;
    name;
    description;
    ref_id;
    automation_candidate;
    automation_status;
    automation_notes;
    test;
    event;
    expected_outcome;
    pre;
    event_qualification;
    associated_entities;
    associated_requirements;
    associated_issues;
    priority;
    classification;
    constructor(init) {
        if (init)
            Object.assign(this, init);
    }
}
