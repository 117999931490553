import store from '@/store';
import { ref, computed, onMounted, } from '@vue/composition-api';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import TestExecutionModal from '@/components/Tests/Modals/TestExecution.vue';
import AssociatorGeneric from '@/components/Generic/Associators/AssociatorGeneric.vue';
import RequirementAssociator from '@/components/Forms/RequirementAssociator.vue';
import GenericAssociator from '@/components/Forms/GenericAssociator.vue';
import coreService from '@/libs/api-services/core-service';
import ListGroupEntity from '@/components/Forms/ListGroups/ListGroupEntity.vue';
import ListGroupRequirement from '@/components/Forms/ListGroups/ListGroupRequirement.vue';
import ListGroupInstance from '@/components/Forms/ListGroups/ListGroupInstance.vue';
import ListGroupConfiguration from '@/components/Forms/ListGroups/ListGroupConfig.vue';
import ListGroupBehaviour from '@/components/Forms/ListGroups/ListGroupBehaviour.vue';
import ListGroupIssue from '@/components/Forms/ListGroups/ListGroupIssue.vue';
import Repeater from '@/components/Forms/Repeater.vue';
import axiosIns from '@/libs/axios';
import { useRouter } from '@core/utils/utils';
import Vue from 'vue';
import { TestLocal } from './TestLocal';
import ListGroupItemIssue from '@/components/Forms/ListGroupItems/ListGroupItemIssue.vue';
import AssociateIssuesCreate from '@/components/AssociatorModals/AssociateIssuesCreate.vue';
import ListGroupItemTest from "@/components/Forms/ListGroupItems/ListGroupItemTest.vue";
import TipTapEditor from "@/components/Forms/TipTapEditor/TipTapEditor.vue";
import Ripple from 'vue-ripple-directive/src/ripple';
export default Vue.extend({
    components: {
        TipTapEditor,
        ListGroupItemTest,
        AssociateIssuesCreate,
        ListGroupItemIssue,
        TestExecutionModal,
        AssociatorGeneric,
        GenericAssociator,
        RequirementAssociator,
        ListGroupRequirement,
        ListGroupEntity,
        ListGroupInstance,
        ListGroupConfiguration,
        ListGroupBehaviour,
        ListGroupIssue,
        Repeater,
    },
    directives: {
        Ripple,
    },
    model: {
        prop: 'isTaskHandlerSidebarActive',
        event: 'update:is-task-handler-sidebar-active',
    },
    props: { selectedTestCase: { type: [TestLocal, Object] } },
    setup(props, { emit, root }) {
        const { route, router } = useRouter();
        const resultsTab = ref(null);
        const selectedTestCase = computed(() => props.selectedTestCase);
        const testLocal = ref({});
        const allComponents = computed(() => store.state.domainModel.components);
        const allConfigurations = computed(() => store.state.configurations.configurations);
        const allIssues = computed(() => store.state.issues.issues);
        const securityClassifications = computed(() => store.getters['constants/securityClassifications']);
        const parsedTestConditions = computed(() => {
            const keywords = ['GIVEN', 'WHEN', 'THEN', 'AND', 'WHERE'];
            const lines = testLocal.value.test?.split('\n') || [];
            return lines.map(l => {
                const lineKeyword = keywords.find(k => l.startsWith(k));
                return { keyword: lineKeyword || '', line: l.replace(lineKeyword, '') };
            });
        });
        const isNewTest = computed(() => !(testLocal.value?.ref_id && testLocal.value?.ref_id !== 'new'));
        const isLoading = ref(true);
        // Selector stuff
        const selectedComponents = ref([]);
        const selectedRequirements = ref([]);
        const allRequirements = ref([]);
        const preConditions = ref([]);
        const events = ref([]);
        const expectedOutcomes = ref([]);
        const testInputConditions = ref([]);
        const testExecutionLatestResult = ref(null);
        const tblTestExecutionData = ref([]);
        const tblTestExecutionSortBy = ref('datetime');
        const tblTestExecutionSortDesc = ref(true);
        const allInstances = ref([]);
        const selectedInstances = ref([]);
        const selectedConfigurations = ref([]);
        const selectedIssues = ref([]);
        const selectedExec = ref(null);
        const selectedBehaviours = ref([]);
        const selectedCaseIssues = ref([]);
        const execIssues = ref([]);
        const testName = ref('');
        const testDesc = ref('');
        const priorityOptions = [
            'Critical',
            'High',
            'Nominal',
            'Low',
        ];
        const tblTestExecutionFields = ref([
            { key: 'build_name', label: 'Build', sortable: true },
            { key: 'datetime', label: 'Execution Time', sortable: true },
            { key: 'result', label: 'Result', sortable: true },
        ]);
        const automationCandidate = ref(false);
        const automationStatus = ref('Not Started');
        const automationStatusOptions = [
            'Not Started',
            'Blocked',
            'In Progress',
            'Completed',
        ];
        const automationNotes = ref('');
        const getTestExecutionResultVariant = testResult => {
            if (testResult.toLowerCase() === 'passed') {
                return 'success';
            }
            else if (testResult.toLowerCase() === 'failed') {
                return 'danger';
            }
            else {
                return 'warning';
            }
        };
        const fetchTest = async () => {
            let testId;
            let testCaseData = props.selectedTestCase;
            if (testCaseData.id) {
                testId = testCaseData.id;
            }
            else {
                testId = route.value.params.testId;
            }
            testId = testId || router.currentRoute.params.testId || testLocal?.value?.id;
            testLocal.value = new TestLocal({ ref_id: 'new' });
            if (testId) {
                return axiosIns.get(`/api/v2/tests/${testId}`).then(({ data }) => {
                    testLocal.value = data;
                    testName.value = testLocal.value.name;
                    testDesc.value = testLocal.value.description || '';
                });
            }
            console.error('no testId to load in fetchTest');
            return Promise.resolve();
        };
        onMounted(async () => {
            testLocal.value = selectedTestCase.value;
            await fetchTest();
            if (!isNewTest.value) {
                syncAll();
            }
            else {
                loadAssociatorLists();
            }
        });
        const saveAssociated = (key, collection, notify = false) => {
            const payload = {
                test_id: testLocal.value.id,
                model: store.state.model.id || route.value.params.modelId,
            };
            // some keys in the payloads don't match their route names, need to remap
            const mappedKey = { requirements: 'reqs', components: 'cpts' }[key] || key;
            payload[mappedKey] = collection;
            axiosIns
                .post(`/api/v2/tests/associate_test_with_${key}`, payload)
                .then(() => {
                if (!notify)
                    return;
                loadAllocations();
                Vue.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Tests associated',
                        text: `Successfully associated ${key} with the Test Case`,
                        icon: 'CheckIcon',
                        variant: 'success',
                    },
                });
            })
                .catch(r => {
                console.error(r);
                Vue.$toast({
                    component: ToastificationContent,
                    props: {
                        title: `Failed to associate ${key}`,
                        text: `An error occurred when attempting to associate ${key} with the Test Case.
              Server returned Status ${r}`,
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                    },
                });
            });
        };
        const saveAssociatedInstances = collection => {
            selectedInstances.value = allInstances.value.filter(ac => collection.includes(ac.id));
            if (!isNewTest.value)
                saveAssociated('instances', collection, true);
        };
        const saveAssociatedRequirements = collection => {
            selectedRequirements.value = allRequirements.value.filter(ac => collection.includes(ac.id));
            if (!isNewTest.value)
                saveAssociated('requirements', collection, true);
        };
        const saveAssociatedComponents = collection => {
            selectedComponents.value = allComponents.value.filter(ac => collection.includes(ac.id));
            if (!isNewTest.value)
                saveAssociated('components', collection, true);
        };
        const saveAssociatedConfigs = collection => {
            selectedConfigurations.value = allConfigurations.value.filter(ac => collection.includes(ac.id));
            if (!isNewTest.value)
                saveAssociated('configs', collection, true);
        };
        const showLinkedIssuesModal = (id, issues) => {
            selectedIssues.value = issues;
            selectedExec.value = id;
            root.$bvModal.show('associator-generic-issues-edit');
        };
        const showNewIssuesModal = (exec_id) => {
            selectedExec.value = exec_id;
            root.$bvModal.show('create-and-allocate-issue-modal');
        };
        const showNewIssuesModalCase = () => {
            selectedExec.value = '';
            root.$bvModal.show('create-and-allocate-issue-modal');
        };
        const associateIssuesExec = (issue) => {
            if (!isNewTest.value) {
                const payload = {
                    exec_id: selectedExec.value,
                    model: store.state.model.id || route.value.params.modelId,
                    issue: issue,
                };
                console.log('Payload: ', payload);
                // some keys in the payloads don't match their route names, need to remap
                axiosIns
                    .post('/api/v2/tests/associate_issue_with_execution', payload)
                    .then(() => {
                    loadExecutionList();
                    Vue.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'New Issue associated with Test Execution',
                            text: 'Successfully created and associated Issue with the Test Execution',
                            icon: 'CheckIcon',
                            variant: 'success',
                        },
                    });
                })
                    .catch(r => {
                    console.error(r);
                    Vue.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Failed to create/associate Issue',
                            text: `An error occurred when attempting to create and associate issues with the Test Execution.
                Server returned Status ${r}`,
                            icon: 'AlertTriangleIcon',
                            variant: 'danger',
                        },
                    });
                });
            }
            selectedExec.value = '';
        };
        const associateIssuesCase = (issue) => {
            selectedCaseIssues.value.push(issue);
            if (!isNewTest.value) {
                const payload = {
                    test_id: testLocal.value.id,
                    model: store.state.model.id || route.value.params.modelId,
                };
                // some keys in the payloads don't match their route names, need to remap
                payload['issues'] = selectedCaseIssues.value.map(i => i.id);
                axiosIns
                    .post(`/api/v2/tests/associate_test_with_issues`, payload)
                    .then(() => {
                    loadAllocations();
                    Vue.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Tests associated',
                            text: `Successfully associated issues with the Test Case`,
                            icon: 'CheckIcon',
                            variant: 'success',
                        },
                    });
                })
                    .catch(r => {
                    console.error(r);
                    Vue.$toast({
                        component: ToastificationContent,
                        props: {
                            title: `Failed to associate issues`,
                            text: `An error occurred when attempting to associate issues with the Test Case.
                Server returned Status ${r}`,
                            icon: 'AlertTriangleIcon',
                            variant: 'danger',
                        },
                    });
                });
            }
        };
        const saveExecIssues = collection => {
            selectedIssues.value = allIssues.value.filter(ac => collection.includes(ac.id));
            if (!isNewTest.value) {
                const payload = {
                    exec_id: selectedExec.value,
                    model: store.state.model.id || route.value.params.modelId,
                    issues: selectedIssues.value.map(i => i.id)
                };
                // some keys in the payloads don't match their route names, need to remap
                axiosIns
                    .post('/api/v2/tests/associate_issues_with_execution', payload)
                    .then(() => {
                    loadExecutionList();
                    Vue.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Issues associated with Test Execution',
                            text: 'Successfully associated Issues with the Test Execution',
                            icon: 'CheckIcon',
                            variant: 'success',
                        },
                    });
                })
                    .catch(r => {
                    console.error(r);
                    Vue.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Failed to associate Issues',
                            text: `An error occurred when attempting to associate issues with the Test Execution.
                Server returned Status ${r}`,
                            icon: 'AlertTriangleIcon',
                            variant: 'danger',
                        },
                    });
                });
            }
        };
        const saveCaseIssues = collection => {
            selectedCaseIssues.value = allIssues.value.filter(ac => collection.includes(ac.id));
            if (!isNewTest.value)
                saveAssociated('issues', collection, true);
        };
        const syncAll = () => {
            syncPreConditions();
            syncEvents();
            syncOutcomes();
            syncTestInputConditions();
            loadAssociatorLists();
            loadAllocations();
            loadExecutionList();
            loadAutomation();
        };
        const loadAssociatorLists = () => {
            syncRequirements();
            syncEntities();
            syncIssues();
            loadConfigurations();
            loadInstances();
        };
        const syncPreConditions = () => {
            preConditions.value = [];
            for (let i = 0; i < testLocal.value.pre.length; i++) {
                preConditions.value.push({
                    id: i,
                    value: testLocal.value.pre[i],
                    label: (i === 0) ? 'GIVEN' : 'AND',
                });
            }
        };
        const syncEvents = () => {
            events.value = [];
            for (let i = 0; i < testLocal.value.event.length; i++) {
                events.value.push({
                    id: i,
                    value: testLocal.value.event[i],
                    label: (i === 0) ? 'Event' : 'AND',
                });
            }
        };
        const syncOutcomes = () => {
            expectedOutcomes.value = [];
            for (let i = 0; i < testLocal.value.expected_outcome.length; i++) {
                expectedOutcomes.value.push({
                    id: i,
                    value: testLocal.value.expected_outcome[i],
                    label: (i === 0) ? 'Expected Outcome' : 'AND',
                });
            }
        };
        const syncTestInputConditions = () => {
            testInputConditions.value = [];
            for (let i = 0; i < testLocal.value.event_qualification.length; i++) {
                testInputConditions.value.push({
                    id: i,
                    value: testLocal.value.event_qualification[i],
                    label: (i === 0) ? 'WHERE' : 'AND',
                });
            }
        };
        const syncRequirements = () => {
            const model = store.state.model.id;
            axiosIns
                .get('/api/v2/requirements/get_requirements_simple', { params: { model } })
                .then(({ data }) => {
                allRequirements.value = data;
            })
                .catch(e => console.error(e));
        };
        const syncEntities = () => {
            store.dispatch('domainModel/getComponents');
        };
        const syncIssues = () => {
            store.dispatch('issues/getIssues');
        };
        const loadConfigurations = () => {
            store.dispatch('configurations/getConfigurations');
        };
        const loadAutomation = () => {
            if (store.state.auth.roles.includes('administrator')) {
                automationCandidate.value = testLocal.value.automation_candidate;
                automationStatus.value = testLocal.value.automation_status;
                automationNotes.value = testLocal.value.automation_notes;
            }
        };
        const loadInstances = () => {
            const modelId = store.state.model.id || route.value.params.modelId;
            const params = { model: modelId };
            axiosIns.get('/api/v2/domain_model/get_all_component_instances', { params })
                .then(({ data }) => {
                allInstances.value = data.map(x => ({ ...x }));
            });
        };
        const loadAllocations = async () => {
            isLoading.value = true;
            selectedRequirements.value = [];
            selectedComponents.value = [];
            selectedInstances.value = [];
            selectedCaseIssues.value = [];
            const promises = [];
            // Associated Requirements
            promises.push(axiosIns.get('/api/v2/tests/get_associated_requirements', { params: { test_id: testLocal.value.id } })
                .then(({ data }) => {
                selectedRequirements.value = data;
            }));
            // Associated components
            promises.push(axiosIns.get('/api/v2/tests/get_associated_components', { params: { test_id: testLocal.value.id } })
                .then(({ data }) => {
                selectedComponents.value = data;
            }));
            // Associated instances
            promises.push(axiosIns.get('/api/v2/tests/get_associated_instances', { params: { test_id: testLocal.value.id } })
                .then(({ data }) => {
                selectedInstances.value = data;
            }));
            // Associated configurations
            promises.push(axiosIns.get('/api/v2/tests/get_associated_configurations', { params: { test_id: testLocal.value.id } })
                .then(({ data }) => {
                selectedConfigurations.value = data;
            }));
            // Associated behaviours
            promises.push(axiosIns.get('/api/v2/tests/get_associated_behaviours', { params: { test_id: testLocal.value.id } })
                .then(({ data }) => {
                selectedBehaviours.value = data;
            }));
            // Associated issues
            promises.push(axiosIns.get('/api/v2/tests/get_associated_issues', { params: { test_id: testLocal.value.id } })
                .then(({ data }) => {
                selectedCaseIssues.value = data;
            }));
            await Promise.all(promises);
            isLoading.value = false;
        };
        const deleteTest = () => {
            root.$bvModal.hide('delete_modal');
            axiosIns.delete(`/api/v2/tests/${testLocal.value.id}`)
                .then(() => {
                emit('remove-task');
                Vue.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Deleted Test Case',
                        icon: 'CheckIcon',
                        text: `Test Case ID: ${testLocal.value.ref_id}`,
                        variant: 'success',
                    },
                });
                testLocal.value = new TestLocal();
                router.push({ name: 'model_tests' });
            })
                .catch(data => {
                console.error(data);
                Vue.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Error deleting Test Case',
                        icon: 'CrossIcon',
                        text: `Test Case ID: ${testLocal.value.ref_id}`,
                        variant: 'danger',
                    },
                });
            });
        };
        const loadLatestTestResult = () => {
            axiosIns.get(`/api/v2/tests/${testLocal.value.id}`)
                .then(r => {
                testExecutionLatestResult.value = r.data.latest_result;
            })
                .catch(r => {
                console.error(r);
            });
        };
        const loadExecutionList = () => {
            isLoading.value = true;
            const params = {
                test_uuid: testLocal.value.id,
            };
            axiosIns.get('/api/v2/tests/get_executions', { params })
                .then(r => {
                tblTestExecutionData.value = r.data;
                loadLatestTestResult();
            })
                .catch(r => {
                console.error(r);
                isLoading.value = false;
            });
        };
        const showTestExecutionModal = () => {
            root.$bvModal.show('test-execution-modal');
            resultsTab.value.activate();
        };
        const assignLocalData = () => {
            /// Assign testLocal all the information it needs to pass back to the API, and validate
            /// Return validation status boolean
            // Reset the below arrays since we are going to pass back the data every time
            // Note that Test Input Conditions can be blank
            testLocal.value.pre = [];
            testLocal.value.event = [];
            testLocal.value.expected_outcome = [];
            testLocal.value.event_qualification = [];
            testLocal.value.name = testName.value;
            testLocal.value.description = testDesc.value;
            if (testLocal.value.name === '')
                return false;
            // We do not want to send .test to the back-end else we will overwrite our changes
            delete testLocal.value.test;
            // Assign the back to testLocal
            for (let i = 0; i < preConditions.value.length; i++) {
                // If a value is empty, warn the user
                if (preConditions.value[i] === '') {
                    return false;
                }
                if (preConditions.value[i] !== 'AND') {
                    testLocal.value.pre[i] = preConditions.value[i].value;
                }
            }
            for (let i = 0; i < events.value.length; i++) {
                // If a value is empty, warn the user
                if (events.value[i] === '') {
                    return false;
                }
                if (events.value[i] !== 'AND') {
                    testLocal.value.event[i] = events.value[i].value;
                }
            }
            for (let i = 0; i < expectedOutcomes.value.length; i++) {
                // If a value is empty, warn the user
                if (expectedOutcomes.value[i] === '') {
                    return false;
                }
                if (expectedOutcomes.value[i] !== 'AND') {
                    testLocal.value.expected_outcome[i] = expectedOutcomes.value[i].value;
                }
            }
            for (let i = 0; i < testInputConditions.value.length; i++) {
                // Allow the field to be blank if there is only 1 field existing, since it may be null
                // Otherwise error out if there are multiple fields but one is blank
                if (testInputConditions.value[i] === '' && testInputConditions.value.length > 1) {
                    return false;
                }
                if (testInputConditions.value[i] === '' && testInputConditions.value.length === 1) {
                    return true;
                }
                if (testInputConditions.value[i] !== 'AND') {
                    testLocal.value.event_qualification[i] = testInputConditions.value[i].value;
                }
            }
            preConditions.value = [];
            return true;
        };
        const submitFunction = async () => {
            if (assignLocalData() === false) {
                Vue.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Empty fields',
                        icon: 'XIcon',
                        text: 'Please ensure none of the fields are blank',
                        variant: 'danger',
                    },
                });
                return {};
            }
            if (store.state.auth.roles.includes('administrator')) {
                testLocal.value.automation_candidate = automationCandidate.value;
                testLocal.value.automation_status = automationStatus.value;
                testLocal.value.automation_notes = automationNotes.value;
            }
            const payload = testLocal.value;
            console.log(payload);
            delete payload.associated_entities;
            delete payload.associated_requirements;
            delete payload.associated_issues;
            try {
                if (!isNewTest.value) {
                    await axiosIns.put(`/api/v2/tests/${testLocal.value.id}`, { test: payload });
                    emit('update-test');
                    syncAll();
                    Vue.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Updated Test Case',
                            icon: 'CheckIcon',
                            text: `Test Case ID: ${testLocal.value.ref_id}`,
                            variant: 'success',
                        },
                    });
                }
                else {
                    // Create New Test case
                    const modelId = store.state.model.id || route.value.params.modelId;
                    const payloadNewTest = {
                        model: modelId,
                        name: payload.name,
                        description: payload.description,
                        pre: payload.pre.filter(s => s !== 'AND'),
                        event: payload.event.filter(s => s !== 'AND'),
                        expected_outcome: payload.expected_outcome.filter(s => s !== 'AND'),
                        priority: payload.priority,
                        classification: payload.classification || store.state.constants.defaultSecurityClassification.id,
                        event_qualification: payload.event_qualification.filter(s => s !== 'AND'),
                        generated: 'user',
                    };
                    if (store.state.auth.roles.includes('administrator')) {
                        payloadNewTest['automation_candidate'] = automationCandidate.value;
                        payloadNewTest['automation_status'] = automationStatus.value;
                        payloadNewTest['automation_notes'] = automationNotes.value;
                    }
                    const data = await axiosIns.post('/api/v2/tests/create_test', payloadNewTest);
                    testLocal.value = data.data;
                    saveAssociated('requirements', selectedRequirements.value.map(r => r.id));
                    saveAssociated('components', selectedComponents.value.map(r => r.id));
                    saveAssociated('configs', selectedConfigurations.value.map(r => r.id));
                    saveAssociated('instances', selectedInstances.value.map(r => r.id));
                    saveAssociated('issues', selectedCaseIssues.value.map(r => r.id));
                    return testLocal;
                }
            }
            catch (err) {
                console.error(err);
                Vue.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Error updating Test Case',
                        icon: 'XIcon',
                        text: `Test Case ID: ${testLocal.value.ref_id}`,
                        variant: 'danger',
                    },
                });
            }
            return payload;
        };
        if (!selectedTestCase.value)
            fetchTest();
        const model = computed(() => store.state.model);
        async function fetchSharedModels() {
            return [model.value, ...await coreService.modelApi.getLinkedModels(this.$store.state.model.id)];
        }
        async function fetchComponents(modelId, subtype) {
            await this.$store.dispatch('domainModel/getComponentsForModel', { modelId, subtype: '' });
            if (modelId && this.$store.state.model.id !== modelId) {
                // now returns all components, not just the subtype
                return this.$store.state.domainModel[modelId].components;
            }
            return this.$store.state.domainModel.components;
        }
        async function linkComponent(cpt) {
            await coreService.componentApi.createComponentTestAllocation(cpt.id, {
                other_id: selectedTestCase.value.id,
                rel_type: 'TESTED_BY',
            });
        }
        async function unlinkComponent(cptId) {
            await coreService.componentApi.deleteComponentAllocation(cptId, selectedTestCase.value.id);
        }
        return {
            fetchSharedModels,
            fetchComponents,
            linkComponent,
            unlinkComponent,
            model,
            // Test Stuff
            getTestExecutionResultVariant,
            tblTestExecutionSortBy,
            tblTestExecutionSortDesc,
            priorityOptions,
            tblTestExecutionFields,
            parsedTestConditions,
            showTestExecutionModal,
            showLinkedIssuesModal,
            showNewIssuesModal,
            showNewIssuesModalCase,
            saveAssociatedInstances,
            saveAssociatedRequirements,
            saveAssociatedComponents,
            saveAssociatedConfigs,
            saveExecIssues,
            saveCaseIssues,
            associateIssuesExec,
            associateIssuesCase,
            submitFunction,
            testLocal,
            testName,
            testDesc,
            isLoading,
            isNewTest,
            selectedComponents,
            allComponents,
            securityClassifications,
            selectedInstances,
            allInstances,
            selectedConfigurations,
            selectedIssues,
            selectedExec,
            allConfigurations,
            allIssues,
            loadExecutionList,
            deleteTest,
            preConditions,
            events,
            expectedOutcomes,
            testInputConditions,
            selectedRequirements,
            selectedBehaviours,
            selectedCaseIssues,
            execIssues,
            testExecutionLatestResult,
            tblTestExecutionData,
            syncAll,
            automationCandidate,
            automationStatus,
            automationStatusOptions,
            automationNotes
        };
    },
});
